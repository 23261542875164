import React from 'react'
import Slider from 'react-slick'

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: null,
      activeSlide: 0,
      progress: 0,
    }
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount = () => {
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)

    if (this.props.heroImgs.length > 1) {
      this.interval = setInterval(() => this.tick(), 10)
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowWidth)
  }

  tick = () => {
    var time = 0
    time += 1 / (6 + 0.1)
    this.setState({
      progress: this.state.progress + time,
    })

    if (this.state.progress >= 100) {
      clearInterval(this.interval)
      this.refs.slider.slickNext()
      this.interval = setInterval(() => this.tick(), 10)
    }
  }

  render() {
    const carouselSettings = {
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      beforeChange: (current, next) => {
        this.setState({ progress: 0 })
        this.setState({ activeSlide: next })
      },
    }

    const { heroImgs, heroImgsMobile } = this.props
    let Image

    if (heroImgs.length > 1 && this.state.windowWidth !== null) {
      Image = (
        <>
          <Slider ref="slider" {...carouselSettings}>
            {heroImgs.map((item, i) => {
              return (
                <img
                  key={i}
                  className="w-full align-bottom"
                  src={
                    this.state.windowWidth >= 768
                      ? heroImgs[i]
                      : heroImgsMobile[i]
                  }
                  alt={heroImgs[i].alt}
                />
              )
            })}
          </Slider>
          <div className="w-full absolute pin-r pin-b">
            <p className="text-lg p-2 lg:px-14 lg:pb-4 text-white">
              0{this.state.activeSlide + 1}
            </p>
            <div className="w-full h-2 overflow-hidden">
              <div
                className="bg-green h-full"
                style={{ width: `${this.state.progress}%` }}
              />
            </div>
          </div>
        </>
      )
    } else if (this.state.windowWidth !== null) {
      Image = (
        <img
          className="w-full align-bottom"
          src={this.state.windowWidth >= 768 ? heroImgs[0] : heroImgsMobile[0]}
          alt="Placeholder"
        />
      )
    }

    return <> {Image && <div className="relative">{Image}</div>} </>
  }
}

export default Hero
